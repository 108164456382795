import React, { useState, useEffect } from 'react';
import { withErrorBoundary } from 'shared';
import { useLocation } from 'react-router-dom';

const ViolationsList = () => {
  const [success, setSuccess] = useState(null);
  const [violations, setViolations] = useState([]);
  const [message, setMessage] = useState(null);
  const location = useLocation(); // Use the useLocation hook to access the current location
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const divStyle = {
    fontSize: '200px',
    textAlign: 'center',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountNo = new URLSearchParams(location.search).get('accountNo'); // Get the query parameter from the location URL
        const response = await Backend.get('/api/v1/users/vantaca_violations', {
          accountNo
        });
        setSuccess(response.success);
        setViolations(response.data);
        setMessage(response.message);
        setError(response.error);
        setLoading(false); // Mark the data loading as completed
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Mark the data loading as completed even if there's an error
      }
    };

    fetchData();
  }, [location]);

  return (
    <div className="CustomMenu container">
      {!loading && (error ? null : (
        <div className="row page-header">
          <div>
            <h5 className="page-title">Violations Information</h5>
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col">
          {!loading && (!error ? null : ( // Show the logo based on the response and loading state
            <div style={divStyle}>
              <i className="fa fa-exclamation-triangle" size="lg" style={{ color: "#d81326" }}></i>
            </div>
          ))}
          {success !== null && (
            <div>
              {success ? (
                <div>
                  {violations.length > 0 ? (
                    violations.map((violation, index) => (
                      <div key={index}>
                        <h6>Violation {index + 1}</h6>
                        <p>Violation Date: {violation.created_date}</p>
                        <p>
                          Violation Status: {violation.closed ? 'Closed' : 'Open'}
                        </p>
                        <p>Violation Type: {violation.type}</p>
                        <p>Violation Subject: {violation.subject}</p>
                        <p>Violation Description: {violation.note}</p>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <p>You have no open Violations at this time.</p>
                  )}
                </div>
              ) : (
                <p>{message}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(ViolationsList);
