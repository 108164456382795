import React, { useState, useEffect } from 'react';
import { withErrorBoundary } from 'shared';
import { useLocation } from 'react-router-dom';


const WorkOrders = () => {
  const [success, setSuccess] = useState(null);
  const [orders, setOrders] = useState([]);
  const [message, setMessage] = useState(null);
  const location = useLocation(); // Use the useLocation hook to access the current location
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const divStyle = {
    fontSize: '200px',
    textAlign: 'center',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountNo = new URLSearchParams(location.search).get('accountNo'); // Get the query parameter from the location URL
        const response = await Backend.get('/api/v1/users/vantaca_work_orders', {
          accountNo
        });
        setSuccess(response.success);
        setOrders(response.data);
        setMessage(response.message);
        setError(response.error);
        setLoading(false); // Mark the data loading as completed
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Mark the data loading as completed even if there's an error
      }
    };

    fetchData();
  }, [location]);

  return (
    <div className="CustomMenu container">
      {!loading && (error ? null : (
        <div className="row page-header">
          <div>
            <h5 className="page-title">Vantaca - Homeowner Work Orders</h5>
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col">
          {!loading && (!error ? null : ( // Show the logo based on the response and loading state
            <div style={divStyle}>
              <i className="fa fa-exclamation-triangle" size="lg" style={{ color: "#d81326" }}></i>
            </div>
          ))}
          {success !== null && (
            <div>
              {success ? (
                <div>
                  {orders.length > 0 ? (
                    orders.map((order, index) => (
                      <div key={index}>
                        <p>Work Order Description: {order.description}</p>
                        <p>Created Date: {order.created_date}</p>
                        <p>
                          Status:{order.closed ? 'Closed' : 'Open'}
                        </p>
                        <p>Recent Activity: {order.step}</p>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <p>
                      You have no open Work Orders at this time.
                    </p>
                  )
                }
                </div>
              ) : (
                <p>{message}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(WorkOrders);
